import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useParams, Link, useNavigate } from "react-router-dom";
import LogoWhite from "../../media/logo_white.svg";

import ReactGA from "react-ga";

import UploadIcon from "../../media/upload_icon.png";

function ApiPlan(props) {
    let navigate = useNavigate();
    let { apiId } = useParams();

    const [stripeDataLoading, setStripeLoading] = useState(true);
    const [stripeData, setStripe] = useState({});
    const [count, setCount] = useState({
        "NumberCall": "", "CountLog": "", "PlanType": ""
    });
    const [usernameOpen, setUsernameOpen] = useState(false);  

    const hideUsernameDropdown = (e) => {
        if (!(e.target.closest('.username'))) {
          if (!(e.target.closest('.username_dropdown'))) {
            setUsernameOpen(false)
          }
        }
      }


    useEffect(() => {
      ReactGA.pageview(window.location.pathname);

      try {
          const apiIdBody = {
              apiId: apiId,
              clientId: props.user.userId
          };
          axios.post(
            `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/sandbox/stripe`,
            apiIdBody
          )
            .then(res => {
              if (res.data.err){
                setStripeLoading(res.data.err);
              } else if (res.data) {
                  console.log(res.data)
                  console.log("res.data")
                  let countNew = count;
                  countNew.NumberCall = res.data.count.NumberCall;
                  countNew.PlanType = res.data.count.PlanType;
                  countNew.CountLog = res.data.countLog.count;
                  setStripe(res.data.stripeData[0]);
                  setCount(countNew);
                  setStripeLoading(false);
              } 
            })
    
        } catch (error) {
          setStripeLoading("An error occurred, please try again later");
        }

    }, []);


    const purchasePlan = async (priceId, type, callNumber) => {

      try {
            const stripeBody = {
              clientId: props.user.userId,
              apiId: apiId,
              planType: type,
              callsNumber: callNumber,
              priceId: priceId,
              url: "https://www.fasteasycheck.com/"
            };
      
            axios.post(
              `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/sandbox/stripe-checkout`,
              stripeBody
            )
              .then(res => {
                window.location.replace(res.data);
              })
  
          } catch (error) {
          }

    }

    return (
      <div className="container" onClick={(e) => hideUsernameDropdown(e)}>
        <div className="navbar_blue">
        <Link to="/"><img className="logo" src={LogoWhite} /></Link>

        <Link to="/" className="btn_white btn_navbar">
            <svg fill="#2139da" width="28px" height="28px">
                <g>
                    <g>
                    <path class="st0" d="M18.7,17.7h-7c-0.6,0-1-0.4-1-1s0.4-1,1-1h7c1.1,0,2-0.9,2-2v-6c0-0.6,0.4-1,1-1s1,0.4,1,1v6
                        C22.7,15.9,20.9,17.7,18.7,17.7z"/>
                    </g>
                    <g>
                    <path class="st0" d="M15.7,21.7c-0.3,0-0.5-0.1-0.7-0.3l-4-4c-0.4-0.4-0.4-1,0-1.4l4-4c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4l-3.3,3.3
                        l3.3,3.3c0.4,0.4,0.4,1,0,1.4C16.2,21.6,16,21.7,15.7,21.7z M10.7,21.7c-0.3,0-0.5-0.1-0.7-0.3l-4-4c-0.4-0.4-0.4-1,0-1.4l4-4
                        c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4l-3.3,3.3l3.3,3.3c0.4,0.4,0.4,1,0,1.4C11.2,21.6,11,21.7,10.7,21.7z"/>
                    </g>
                </g>
            </svg>
          {props.lang == "spanish" ? "Regresar" : "Return"}
        </Link>

        <div className="btn_blue_check_container">
            <div className={"btn_blue btn_checkbox" + (props.lang == "spanish" ? " selected" : "")} onClick={() => props.changeLanguage("spanish")}>{props.lang == "spanish" ? "Español" : "Spanish"}</div>
            <div className={"btn_blue btn_checkbox" + (props.lang == "english" ? " selected" : "")} onClick={() => props.changeLanguage("english")}>{props.lang == "spanish" ? "Inglés" : "English"}</div>
          </div>

            <div className="username" onClick={() => setUsernameOpen(!usernameOpen)}>
              <span>{props.user.username}</span>
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 10l5 5 5-5z"/></svg>
            </div>
            {
              usernameOpen &&
              <div className="username_dropdown">
                <h3>{props.lang == "spanish" ? "Mi Cuenta" : "My Account"}</h3>
                <svg onClick={() => setUsernameOpen(!usernameOpen)} className="username_dropdown_close" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
                <div className="username_info">
                  <p><span>{props.lang == "spanish" ? "Nombre " : "Name "}:</span> {props.user.username}</p>
                  <p><span>{props.lang == "spanish" ? "Empresa " : "Company "} :</span> -</p>
                </div>
                <h3>{props.lang == "spanish" ? "APIs Activas" : "Active APIs"}</h3>
                <div className="methods_list">
                {
                  props.methodsList.length > 0 &&
                  props.methodsList
                  .filter(method => method.ApiName.toLowerCase() != "moffin"
                      && method.ApiName.toLowerCase() != "facturapi"
                      && method.ApiName.toLowerCase() != "weesing"
                      && method.ApiName.toLowerCase() != "weesign"
                      && method.ApiName.toUpperCase() != "COPOMEX (CÓDIGO POSTAL MEXICANO) "
                      && method.ApiName.toUpperCase() != "EXCHANGE RATES"
                      && method.ApiName.toLowerCase() != "palenca"
                      && method.ApiName.toLowerCase() != "copomex"
                      && method.ApiName.toLowerCase() != "auto api mapas"
                      && method.ApiName.toUpperCase() != "PERSON ANALYTICS"
                  )
                  .map((method, index) =>
                    <div className="username_api">
                      <h4>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 10l5 5 5-5z"/></svg>
                        {method.ApiName}
                      </h4>
                      <p>{props.lang == "spanish" ? "Paquete" : "Package"} {method.PlanType} {props.lang == "spanish" ? "consultas" : "consultations"}</p>
                      <p className="flex">
                        <span>{props.lang == "spanish" ? "Disponibles" : "Available"}: {method.NumberCall}</span>
                      </p>
                    </div>
                  )
                }
                </div>
                <div className="btn_username_container">
                  <div className="btn_blue_check_container">
                    <div className={"btn_blue btn_checkbox" + (props.lang == "spanish" ? " selected" : "")} onClick={() => props.changeLanguage("spanish")}>{props.lang == "spanish" ? "Español" : "Spanish"}</div>
                    <div className={"btn_blue btn_checkbox" + (props.lang == "english" ? " selected" : "")} onClick={() => props.changeLanguage("english")}>{props.lang == "spanish" ? "Inglés" : "English"}</div>
                  </div>
                  <div className="btn_white" onClick={props.logout()}>{props.lang == "spanish" ? "Salir" : "Exit"}</div>
                </div>
              </div>
            }
        </div>
        <div className="content">
            <div className="title_text">
                <h2>{props.lang == "spanish" ? "Planes y precios" : "Plans and Prices"}</h2>
            </div>
            {!stripeDataLoading ? <div className="send_request_boxs">
                
                <div className="send_request_box plan_box">
                  <div className="send_request_header">
                      {props.lang == "spanish" ? "Plan Básico" : "Basic Plan"}
                  </div>
                  <div className="send_request_body">
                    <p class="plan_call_count">{stripeData.BasicPlanCalls} {props.lang == "spanish" ? "Llamadas" : "Calls"}</p>
                    <p class="plan_price">{stripeData.BasicPlanPriceMXN} <span class="plan_currency">MXN</span></p>
                    <p class="plan_price">{stripeData.BasicPlanPriceUSD} <span class="plan_currency">USD</span></p>
                    <div className="btn_blue" onClick={() => purchasePlan(stripeData.BasicPlan, "basic", stripeData.BasicPlanCalls)}>{props.lang == "spanish" ? "Comprar" : "Purchase"}</div>
                  </div>
                </div>
                <div className="send_request_box plan_box">
                    <div className="send_request_header">
                        {props.lang == "spanish" ? "Plan Completo" : "Full Plan"}
                    </div>
                    <div className="send_request_body">
                      <p class="plan_call_count">{stripeData.FullPlanCalls} {props.lang == "spanish" ? "Llamadas" : "Calls"}</p>
                      <p class="plan_price">{stripeData.FullPlanPriceMXN} <span class="plan_currency">MXN</span></p>
                      <p class="plan_price">{stripeData.FullPlanPriceUSD} <span class="plan_currency">USD</span></p>
                      <div className="btn_blue" onClick={() => purchasePlan(stripeData.FullPlan, "full", stripeData.FullPlanCalls)}>{props.lang == "spanish" ? "Comprar" : "Purchase"}</div>
                    </div>
                </div>
            </div> : <></>}
        </div>
        <div className="count_sticker">
            <div className="count_label"><span style={props.lang == "english" ? {width: 185, right: -75} : {} }>{props.lang == "spanish" ? "CONTADOR DE CONSTULTAS" : "CONSULT COUNTER"}</span></div>
            <div className="count_container">
                <h5>{props.lang == "spanish" ? "CONTADOR DE CONSTULTAS" : "CONSULT COUNTER"}</h5>
                <p>{props.lang == "spanish" ? "Paquete" : "Package"}<br /><strong>
                {count.PlanType != "" ? count.PlanType : "-" }
                </strong></p>
                <p className="count_info">{props.lang == "spanish" ? "Realizadas" : "Performed" }: <span>
                  {count.CountLog != "" ? count.CountLog : "-" }
                </span></p>
                <p className="count_info">{props.lang == "spanish" ? "Disponibles" : "Available"}: <span>
                  {count.NumberCall != "" ? count.NumberCall : "-" }
                </span></p>
                <div className="count_bar"><div className="count_disponible_bar"></div></div>
            </div>
        </div>
      </div>
    );

}

export default ApiPlan;