import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useParams, Link, useNavigate } from "react-router-dom";
import LogoWhite from "../../media/logo_white.svg";

import ReactGA from "react-ga";

import UploadIcon from "../../media/upload_icon.png";

function ApiRequest(props) {
    let navigate = useNavigate();
    let { endPointId } = useParams();

    const [selectParam, setSelectParam] = useState(false);

    const [rightsCheck, setRightsCheck] = useState(false);

    const [paramsLoading, setParamsLoading] = useState(true);
    const [paramsList, setParamsList] = useState([]);
    const [count, setCount] = useState({
        "Name": "", "Description": "", "NameEnglish": "", "DescriptionEnglish": "", "CallLimit": "", "NumberCall": "", "CountLog": "", "PlanType": ""
    });
    const [errorParams, setErrorParams] = useState("");
    const [errorRequest, setErrorRequest] = useState(false);
    const [requestParams, setrequestParams] = useState({});
    const [requestParamsFiles, setRequestParamsFiles] = useState({});
    const [requestLoading, setRequestLoading] = useState(false);
    const [requestBatchLoading, setRequestBatchLoading] = useState(false);
    const [usernameOpen, setUsernameOpen] = useState(false);  
    const [apiId, setApiId] = useState("");  
    const [requestError, setRequestError] = useState(false);  
    const [requestMobileType, setRequestMobileType] = useState("manual");  

    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");
    const [downloadLink, setDownloadLink] = useState("#");
    const fileReader = new FileReader();
    const handleInputFileOnChange = (e) => {
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
    };
    const batchRequestSend = () => {
        setRequestBatchLoading(true);
        if (file) {
            fileReader.onload = async function (event) {
                var finalCSV = [];
                var result = event.target.result;
                var resultArray = result.split("\n");      
                var i = 0;
                for (i = 0; i < resultArray.length; i++) {
                    // var eachRequest = ((
                    //     paramsList.map(param => param.Description).includes("application/json") 
                    // ? "application/json," : "") + resultArray[i]).replaceAll(/\s/g, "").split(",");
                    var eachRequest = ((
                        paramsList.map(param => param.Description).includes("application/json") 
                    ? "application/json," : "") + resultArray[i]).replaceAll("\r", "").split(",");
                    const requestBody = {
                        "idEndPoint": endPointId,
                        "params": paramsList
                            .map((param, paramIndex) => {
                            return {"name": param["Name"], "value": eachRequest[paramIndex]}
                        })
                    };    
                    try {
                        console.log(requestBody);
                        await axios.post(
                            `https://services.scorce.io/api/apis/consume`,
                            requestBody,
                            {
                                headers: {
                                    'Content-Type': 'application/json',
                                    'PublicApiKey': props.user.userKey
                                }
                            }
                        )
                        .then(res => {
                            console.log(resultArray[i])
                            console.log(res.data);
                            finalCSV.push(resultArray[i].replaceAll(/\s/g, "") + "," + JSON.stringify(res.data) + "\n");
                        })
                    } catch (error) {
                        console.log(error);
                        //    setErrorParams("An error occurred, please try again later");
                    }
                }
                console.log(finalCSV.join(""));
                setDownloadLink("data:text/csv;charset=utf-8," + encodeURI(finalCSV.join("")));
                setRequestBatchLoading(false);
            };

            fileReader.readAsText(file);
        }
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);

        try {
            const endPointIdBody = {
                endpointId: endPointId,
                clientId: props.user.userId
            };
            axios.post(
              `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/sandbox/endpoint-params`,
              endPointIdBody
            )
              .then(res => {
                if (res.data.err){
                    setErrorParams(res.data.err);
                } else if (res.data) {
                    console.log(res.data)
                    console.log("res.data")
                    setParamsList(res.data.params);
                    let countNew = count;
                    setApiId(res.data.count.ApiId);
                    countNew.Name = res.data.count.Name;
                    countNew.Description = res.data.count.Description;            
                    countNew.NameEnglish = res.data.count.NameEnglish;
                    countNew.DescriptionEnglish = res.data.count.DescriptionEnglish;    
                    countNew.CallLimit = res.data.count.CallLimit;
                    countNew.NumberCall = res.data.count.NumberCall;                
                    countNew.PlanType = res.data.count.PlanType;
                    countNew.CountLog = res.data.countLog.count;
                    setCount(countNew);
                    res.data.params.map( param => {
                        if (param.Description == "application/json") {
                            setrequestParams(prevState => ({
                                ...prevState,
                                [param.Name]: "application/json"
                            }));
                        } else if (param.Required) {
                            setrequestParams(prevState => ({
                                ...prevState,
                                [param.Name]: ""
                            }));
                        }
                    });
                    setParamsLoading(false);
                } 
              })
      
          } catch (error) { 
            setErrorParams("An error occurred, please try again later");
          }
  
      }, []);

    const handleChange = (name, value) => {
        setrequestParams(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleIntChange  = (name, value) => {
        setrequestParams(prevState => ({
            ...prevState,
            [name]: parseInt(value)
        }));
    };

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(',')[1]);
        reader.onerror = error => reject(error);
    });

    const handleFileChange = async (name, value) => {
        const base64File = await toBase64(value.files[0]);
        setrequestParams(prevState => ({
            ...prevState,
            [name]: base64File
        }));
        setRequestParamsFiles(prevState => ({
            ...prevState,
            [name]: value.files[0].name
        }));
    };

    const sendRequest = () => {
        setErrorRequest(false);

        if (rightsCheck) {
            
            let params = []

            Object.keys(requestParams).map(key => {
                params.push({"name": key, "value": requestParams[key]})
            });

            if (
                !(paramsList.filter(param => param.Required).map(param => param.Name)
                .map(param => requestParams[param] ? requestParams[param].length : 0)
                .filter(param => !param)
                .length == 0)
            ) {
                setRequestError(true);
            } else {
                const requestBody = {
                    clientId: props.user.userId,
                    endpointId: endPointId,
                    params: params
                };                
                setRequestLoading(true);
                setRequestError(false);
                try {    
                    axios.post(
                        `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/sandbox/send-request`,
                        requestBody
                    )
                    .then(res => {
                        if (res.data.err) {
                            if (res.data.err == "Out of balance") {
                                setErrorRequest("Out of balance");
                            } else {
                                setErrorRequest("Try again");
                                console.log("error1");
                                console.log(res); 
                            }
                        } else if(res.data) {
                            navigate("/api-result/" + res.data);
                        }
                        setRequestLoading(false);
                    })
                } catch (error) {
                    console.log("error");
                    console.log(error); 
                    setErrorParams("An error occurred while sending the request, please try again later");
                    setRequestLoading(false);
                }    
            }
                        
        } else {
            setErrorRequest("checkbox rights");
        }


    }

    const hideUsernameDropdown = (e) => {
        if (!(e.target.closest('.username'))) {
          if (!(e.target.closest('.username_dropdown'))) {
            setUsernameOpen(false)
          }
        }
        if (!(e.target.closest('.send_request_select_container'))) {
              setSelectParam(false);
        }
      }


    return (
      <div className="container" onClick={(e) => hideUsernameDropdown(e)}>
        <div className="navbar_blue">
        <Link to="/"><img className="logo" src={LogoWhite} /></Link>

        <Link to="/" className="btn_white btn_navbar">
            <svg fill="#2139da" width="28px" height="28px">
                <g>
                    <g>
                    <path class="st0" d="M18.7,17.7h-7c-0.6,0-1-0.4-1-1s0.4-1,1-1h7c1.1,0,2-0.9,2-2v-6c0-0.6,0.4-1,1-1s1,0.4,1,1v6
                        C22.7,15.9,20.9,17.7,18.7,17.7z"/>
                    </g>
                    <g>
                    <path class="st0" d="M15.7,21.7c-0.3,0-0.5-0.1-0.7-0.3l-4-4c-0.4-0.4-0.4-1,0-1.4l4-4c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4l-3.3,3.3
                        l3.3,3.3c0.4,0.4,0.4,1,0,1.4C16.2,21.6,16,21.7,15.7,21.7z M10.7,21.7c-0.3,0-0.5-0.1-0.7-0.3l-4-4c-0.4-0.4-0.4-1,0-1.4l4-4
                        c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4l-3.3,3.3l3.3,3.3c0.4,0.4,0.4,1,0,1.4C11.2,21.6,11,21.7,10.7,21.7z"/>
                    </g>
                </g>
            </svg>
          {props.lang == "spanish" ? "Regresar" : "Return"}
        </Link>

        <div className="btn_blue_check_container">
            <div className={"btn_blue btn_checkbox" + (props.lang == "spanish" ? " selected" : "")} onClick={() => props.changeLanguage("spanish")}>{props.lang == "spanish" ? "Español" : "Spanish"}</div>
            <div className={"btn_blue btn_checkbox" + (props.lang == "english" ? " selected" : "")} onClick={() => props.changeLanguage("english")}>{props.lang == "spanish" ? "Inglés" : "English"}</div>
          </div>

            <div className="username" onClick={() => setUsernameOpen(!usernameOpen)}>
              <span>{props.user.username}</span>
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 10l5 5 5-5z"/></svg>
            </div>
            {
              usernameOpen &&
              <div className="username_dropdown">
                <h3>{props.lang == "spanish" ? "Mi Cuenta" : "My Account"}</h3>
                <svg onClick={() => setUsernameOpen(!usernameOpen)} className="username_dropdown_close" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
                <div className="username_info">
                  <p><span>{props.lang == "spanish" ? "Nombre " : "Name "}:</span> {props.user.username}</p>
                  <p><span>{props.lang == "spanish" ? "Empresa " : "Company "} :</span> -</p>
                </div>
                <h3>{props.lang == "spanish" ? "APIs Activas" : "Active APIs"}</h3>
                <div className="methods_list">
                {
                  props.methodsList.length > 0 &&
                  props.methodsList
                  .filter(method => method.ApiName.toLowerCase() != "moffin"
                      && method.ApiName.toLowerCase() != "facturapi"
                      && method.ApiName.toLowerCase() != "weesing"
                      && method.ApiName.toLowerCase() != "weesign"
                      && method.ApiName.toUpperCase() != "COPOMEX (CÓDIGO POSTAL MEXICANO) "
                      && method.ApiName.toUpperCase() != "EXCHANGE RATES"
                      && method.ApiName.toLowerCase() != "palenca"
                      && method.ApiName.toLowerCase() != "copomex"
                      && method.ApiName.toLowerCase() != "auto api mapas"
                      && method.ApiName.toUpperCase() != "PERSON ANALYTICS"
                  )
                  .map((method, index) =>
                    <div className="username_api">
                      <h4>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 10l5 5 5-5z"/></svg>
                        {method.ApiName}
                      </h4>
                      <p>{props.lang == "spanish" ? "Paquete" : "Package"} {method.PlanType} {props.lang == "spanish" ? "consultas" : "consultations"}</p>
                      <p className="flex">
                        <span>{props.lang == "spanish" ? "Disponibles" : "Available"}: {method.NumberCall}</span>
                      </p>
                    </div>
                  )
                }
                </div>
                <div className="btn_username_container">
                  <div className="btn_blue_check_container">
                    <div className={"btn_blue btn_checkbox" + (props.lang == "spanish" ? " selected" : "")} onClick={() => props.changeLanguage("spanish")}>{props.lang == "spanish" ? "Español" : "Spanish"}</div>
                    <div className={"btn_blue btn_checkbox" + (props.lang == "english" ? " selected" : "")} onClick={() => props.changeLanguage("english")}>{props.lang == "spanish" ? "Inglés" : "English"}</div>
                  </div>
                  <div className="btn_white" onClick={props.logout()}>{props.lang == "spanish" ? "Salir" : "Exit"}</div>
                </div>
              </div>
            }
        </div>
        <div className="content">
            <div className="title_text">
                <h2>{props.lang == "spanish" ? count.Name : count.NameEnglish}</h2>
                <p className="method_description">{props.lang == "spanish" ? count.Description : count.DescriptionEnglish}</p>
            </div>
            <div className="btn_blue_check_container request_mobile_type">
                <div className={"btn_blue btn_checkbox" + (requestMobileType == "batch" ? " selected" : "")} onClick={() => setRequestMobileType("batch")}>{props.lang == "spanish" ? "Verificación batch" : "Batch verification"}</div>
                <div className={"btn_blue btn_checkbox" + (requestMobileType == "manual" ? " selected" : "")} onClick={() => setRequestMobileType("manual")}>{props.lang == "spanish" ? "Verificación manual" : "Manual verification"}</div>
            </div>
            <div className="send_request_boxs">
                <div className={"send_request_box" + (requestMobileType == "batch" ? " send_request_box_show_mobile" : "")}>
                    <div className="send_request_header">
                        {props.lang == "spanish" ? "Verificación batch" : "Batch verification"}
                    </div>
                    <div className="send_request_body">
                        <input type="file" id="request_batch_input" accept=".csv" onChange={handleInputFileOnChange}/>
                        <label for="request_batch_input" className="batch_input">
                            <div className="upload_icon">
                                <img src={UploadIcon}/>
                            </div>
                            {
                                fileName.length == 0
                                ? <span>{props.lang == "spanish" ? "Elige un archivo o arrástralo aquí" : "Choose a file or drag and drop it here"}</span>
                                : <span>{fileName}</span>
                            }
                        </label>
                        <span className="send_request_info">
                        {/* {   props.lang == "spanish" ?
                            <>*Usar archivo CSV y agregar una coma al final de cada campo.<br />*Agregar el signo "+" y el codigo de país de casa número</>
                            :
                            <>*Use CSV file and add a comma at the end of each field.<br />*Add the "+" sign and the country code of the home number</>
                        }                             */}
                        </span>
                        <div className={"btn_white" + (requestBatchLoading ? " btn_disable" : "")} onClick={batchRequestSend}>
                            {
                                requestBatchLoading
                                ? props.lang == "spanish" ? "Cargando" : "Loading"
                                : props.lang == "spanish" ? "Verificar" : "Check"
                            }
                        </div>
                        <a className={"btn_white btn_outline" + (downloadLink != "#" ? "" : " btn_disable")} href={downloadLink}>{props.lang == "spanish" ? "Descargar" : "Download"}</a>
                    </div>
                </div>
                <div className={"send_request_box" + (requestMobileType == "manual" ? " send_request_box_show_mobile" : "")}>
                    <div className="send_request_header">
                        {props.lang == "spanish" ? "Verificación manual" : "Manual verification"}
                    </div>
                    <div className="send_request_body">
                        {
                            !paramsLoading && paramsList.map(param => 
                                param.Type == "HEADER"
                                ? param.Description == "application/json"
                                ? <></>
                                : <>
                                    <span className="send_request_label">{param.Name + (param.Required ? "*" : "")}</span>
                                    <select
                                        className="send_request_label"
                                        value={
                                            param.Description
                                            .replace("Available values : ", "")
                                            .split(", ")[0]
                                            .replace("Default value : ", "")
                                        }
                                    >{param.Description.replace("Available values : ", "")
                                    .split(", ")
                                    .map(option => 
                                        !(option.includes("Default value : "))
                                        ? <option value={option}>{option}</option> : <></>
                                    )}</select>
                                </>
                                : param.DataType == "String" && param.DescriptionEnglish.toLowerCase().includes("base 64")
                                ? <>
                                    <span className="send_request_label"></span>
                                    <input id={param.Name} className="send_request_input" type="file" placeholder={props.lang == "spanish" ? param.Description : param.DescriptionEnglish} onChange={(e) => handleFileChange(param.Name, e.target)}/>
                                    <label for={param.Name}>
                                        <div>{param.Name + (param.Required ? "*" : "")}</div>
                                        <span>{requestParamsFiles[param.Name] ? requestParamsFiles[param.Name] : props.lang == "spanish" ? "Seleccione el archivo" : "Select file"}</span>
                                    </label>
                                </>

                                : (
                                    (param.EndPointId == '8336f573-0967-4824-9965-7278c0ad6c8f' && param.Name == "type")
                                    ||
                                    (param.EndPointId == '45058735-264a-4a16-a02e-08c399509e91' && param.Name == "type")
                                )
                                ? <>
                                    <div className={"send_request_select_container" + (selectParam ? " active" : "")} onClick={() => setSelectParam(!selectParam)}>
                                        <div className="send_request_select_list">
                                            <div className="send_request_select_item" onClick={() => handleChange(param.Name, "full_name")}><span>full_name</span></div>
                                            <div className="send_request_select_item" onClick={() => handleChange(param.Name, "document_id")}><span>document_id</span></div>
                                        </div>
                                        <div className={"send_request_input send_request_select" + (requestParams[param.Name] ? " send_request_select_selected" : "" )}>
                                            {
                                                requestParams[param.Name]
                                                ? requestParams[param.Name]
                                                : param.Name + (param.Required ? "*" : "")
                                            }
                                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="6 6 12 12" width="24px" fill="#2139da"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M8.71 11.71l2.59 2.59c.39.39 1.02.39 1.41 0l2.59-2.59c.63-.63.18-1.71-.71-1.71H9.41c-.89 0-1.33 1.08-.7 1.71z"/></svg>
                                        </div>
                                    </div>
                                </>

                                : param.EndPointId == '0a08d0d7-a92a-48ae-bb60-a4f5e6996202' && param.Name == "documentType"
                                ? <>
                                    <div className={"send_request_select_container" + (selectParam ? " active" : "")} onClick={() => setSelectParam(!selectParam)}>
                                        <div className="send_request_select_list">
                                            <div className="send_request_select_item" onClick={() => handleChange(param.Name, "DNI")}><span>DNI</span></div>
                                        </div>
                                        <div className={"send_request_input send_request_select" + (requestParams[param.Name] ? " send_request_select_selected" : "" )}>
                                            {
                                                requestParams[param.Name]
                                                ? requestParams[param.Name]
                                                : param.Name + (param.Required ? "*" : "")
                                            }
                                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="6 6 12 12" width="24px" fill="#2139da"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M8.71 11.71l2.59 2.59c.39.39 1.02.39 1.41 0l2.59-2.59c.63-.63.18-1.71-.71-1.71H9.41c-.89 0-1.33 1.08-.7 1.71z"/></svg>
                                        </div>
                                    </div>
                                </>

                                : param.EndPointId == 'd47af076-5919-40cd-92b8-061427aee328' && param.Name == "documentType"
                                ? <>
                                    <div className={"send_request_select_container" + (selectParam ? " active" : "")} onClick={() => setSelectParam(!selectParam)}>
                                        <div className="send_request_select_list">
                                            <div className="send_request_select_item" onClick={() => handleChange(param.Name, "RUC")}><span>RUC</span></div>
                                        </div>
                                        <div className={"send_request_input send_request_select" + (requestParams[param.Name] ? " send_request_select_selected" : "" )}>
                                            {
                                                requestParams[param.Name]
                                                ? requestParams[param.Name]
                                                : param.Name + (param.Required ? "*" : "")
                                            }
                                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="6 6 12 12" width="24px" fill="#2139da"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M8.71 11.71l2.59 2.59c.39.39 1.02.39 1.41 0l2.59-2.59c.63-.63.18-1.71-.71-1.71H9.41c-.89 0-1.33 1.08-.7 1.71z"/></svg>
                                        </div>
                                    </div>
                                </>

                                : param.EndPointId == '6ace3b8e-0ccc-4a1e-b8df-e8b0a99f697b' && param.Name == "documentType"
                                ? <>
                                    <div className={"send_request_select_container" + (selectParam ? " active" : "")} onClick={() => setSelectParam(!selectParam)}>
                                        <div className="send_request_select_list">
                                            <div className="send_request_select_item" onClick={() => handleChange(param.Name, "CCEC")}><span>CCEC</span></div>
                                        </div>
                                        <div className={"send_request_input send_request_select" + (requestParams[param.Name] ? " send_request_select_selected" : "" )}>
                                            {
                                                requestParams[param.Name]
                                                ? requestParams[param.Name]
                                                : param.Name + (param.Required ? "*" : "")
                                            }
                                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="6 6 12 12" width="24px" fill="#2139da"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M8.71 11.71l2.59 2.59c.39.39 1.02.39 1.41 0l2.59-2.59c.63-.63.18-1.71-.71-1.71H9.41c-.89 0-1.33 1.08-.7 1.71z"/></svg>
                                        </div>
                                    </div>
                                </>

                                : param.EndPointId == '2e5e406c-7655-4cc9-b9b5-f0452d750bca' && param.Name == "sex"
                                ? <>
                                    <div className={"send_request_select_container" + (selectParam ? " active" : "")} onClick={() => setSelectParam(!selectParam)}>
                                        <div className="send_request_select_list">
                                            <div className="send_request_select_item" onClick={() => handleChange(param.Name, "H")}><span>H</span></div>
                                            <div className="send_request_select_item" onClick={() => handleChange(param.Name, "M")}><span>M</span></div>
                                        </div>
                                        <div className={"send_request_input send_request_select" + (requestParams[param.Name] ? " send_request_select_selected" : "" )}>
                                            {
                                                requestParams[param.Name]
                                                ? requestParams[param.Name]
                                                : param.Name + (param.Required ? "*" : "")
                                            }
                                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="6 6 12 12" width="24px" fill="#2139da"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M8.71 11.71l2.59 2.59c.39.39 1.02.39 1.41 0l2.59-2.59c.63-.63.18-1.71-.71-1.71H9.41c-.89 0-1.33 1.08-.7 1.71z"/></svg>
                                        </div>
                                    </div>
                                </>

                                : param.DataType == "String"
                                ? <>
                                    <input className="send_request_input" type="text" placeholder={param.Name + (param.Required ? "*" : "")} onChange={(e) => handleChange(param.Name, e.target.value)}/>
                                </>

                                : param.DataType == "String"
                                ? <>
                                    <span className="send_request_label">{param.Name + (param.Required ? "*" : "")}</span>
                                    <input className="send_request_input" type="text" placeholder={props.lang == "spanish" ? param.Description : param.DescriptionEnglish} onChange={(e) => handleChange(param.Name, e.target.value)}/>
                                </>

                                : param.DataType == "Integer" || param.DataType == "Integer "
                                ? <>
                                    <span className="send_request_label">{param.Name + (param.Required ? "*" : "")}</span>
                                    <input className="send_request_input" type="number" placeholder={props.lang == "spanish" ? param.Description : param.DescriptionEnglish} onChange={(e) => handleIntChange(param.Name, e.target.value)}/>
                                </>

                                : param.DataType == "Boolean" 
                                ? <div>Switch : {param.Description + (param.Required ? "*" : "")}</div>
                                : <p>Format not supported</p>
                            )
                        }
                        <span className="send_request_info">                        
                            {props.lang == "spanish" ? "* Campos obligatorios" : "* Required fields"}
                        </span>
                        {(errorRequest && errorRequest != "checkbox rights") ? 
                            errorRequest == "Out of balance"
                                ?
                                <span class="send_request_error">{props.lang == "spanish" ? " No hay saldo disponible" : "Out of balance"}</span>
                                :
                                <span class="send_request_error">{props.lang == "spanish" ? "Inténtalo de nuevo" : "Try Again"}</span>
                            :
                            requestError ? <span class="send_request_error">{props.lang == "spanish" ? "Campo vacío" : "Empty field"}</span> : <></>
                            }
                        <div className={"btn_white" + (requestLoading ? " btn_disable" : "")} onClick={() => sendRequest()}>
                            {
                                requestLoading
                                ? props.lang == "spanish" ? "Cargando" : "Loading"
                                : props.lang == "spanish" ? "Verificar" : "Check"
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className={"license_footer" + (errorRequest == "checkbox rights" ? " license_footer_error" : "")}>
                <h6 onClick={() => {
                    setRightsCheck(!rightsCheck);
                    if (errorRequest == "checkbox rights") {
                        setErrorRequest("");
                    }
                }}>
                    {
                        rightsCheck ?
                        <svg className="checked" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/></svg>
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/></svg>
                    }
                    {props.lang == "spanish" ? "Declaro que cuento con la autorización necesaria" : "I declare that I have the necessary authorization."}</h6>
                <p>{props.lang == "spanish" ? "De acuerdo a las nomas correspondientes para acceder a la información personal relacionada con los documentos y/o nombres que seran consultados haciendo uso de esta herramienta" : "According to the corresponding rules for accessing personal information related to the documents and/or names that will be consulted using this tool."}</p>
            </div>
            <div className="center_btn">
                <Link to={"/api-plan/" + apiId} className="btn_blue btn_outline">{props.lang == "spanish" ? "Compra un plan" : "Get a plan"}</Link>
                <Link to={"/api-log/" + endPointId} className="btn_blue btn_outline">{props.lang == "spanish" ? "Historial de consultas" : "Query history"}</Link>
            </div>
        </div>
        <div className="count_sticker">
            <div className="count_label"><span style={props.lang == "english" ? {width: 185, right: -75} : {} }>{props.lang == "spanish" ? "CONTADOR DE CONSTULTAS" : "CONSULT COUNTER"}</span></div>
            <div className="count_container">
                <h5>{props.lang == "spanish" ? "CONTADOR DE CONSTULTAS" : "CONSULT COUNTER"}</h5>
                <p>{props.lang == "spanish" ? "Paquete" : "Package"}<br /><strong>
                {count.PlanType != "" ? count.PlanType : "-" }
                </strong></p>
                <p className="count_info">{props.lang == "spanish" ? "Realizadas" : "Performed" }: <span>
                  {count.CountLog}
                </span></p>
                <p className="count_info">{props.lang == "spanish" ? "Disponibles" : "Available"}: <span>
                  {count.NumberCall != "" ? count.NumberCall : "-" }
                </span></p>
                <div className="count_bar"><div className="count_disponible_bar"></div></div>
            </div>
        </div>
      </div>
    );

}

export default ApiRequest;