import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import { JsonToTable } from "react-json-to-table";
import { toPng } from 'html-to-image';

import ReactGA from "react-ga";

import LogoWhite from "../../media/logo_white.svg";

function ApiLog(props) {

  let { endPointId } = useParams();
    
  const [logsLoading, setLogsLoading] = useState(true);
  const [logsList, setLogsList] = useState([]);
  const [tableHead, setTableHead] = useState([]);
  const [errorLogs, setErrorLogs] = useState("loading");
  const [logsJsonList, setLogsJsonList] = useState("[]");
  const [count, setCount] = useState({
    "CallLimit": "", "NumberCall": "", "CountLog": "", "PlanType": ""
  });
  const tableResult = useRef(null);

  const [usernameOpen, setUsernameOpen] = useState(false);

  const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

  useEffect(() => {
      ReactGA.pageview(window.location.pathname);


      try {
        const endPointIdBody = { endpointId: endPointId, clientId: props.user.userId };
      
          axios.post(
            `https://u439lq97h7.execute-api.us-west-2.amazonaws.com/dev/sandbox/endpoint-logs`,
            endPointIdBody
          )
            .then(res => {
              if (res.data.err){
                setErrorLogs(res.data.err);
                setLogsLoading(false);
              } else if (res.data) {
                if (res.data.logs) {
                  let countNew = count;
                    countNew.CallLimit = res.data.count.CallLimit;
                    countNew.NumberCall = res.data.count.NumberCall;                  
                    countNew.PlanType = res.data.count.PlanType;
                    countNew.CountLog = res.data.countLog.count;
                  setCount(countNew);
                  setTableHead(
                    [].concat.apply([], 
                      res.data.logs
                      .map(log => JSON.parse(log.JsonRequest)
                      .map(jsonReq => jsonReq.name)) 
                    ).filter(function(item, pos, self) {
                      return self.indexOf(item) == pos;
                    })
                    .filter(param => param != "content-type")
                  );  
                }
                console.log(res.data);
                setLogsList(
                  res.data.logs
                  .map(log => {
                    let newJsonRequest = {};
                    if (log.JsonRequest) {
                      JSON.parse(log.JsonRequest)
                      .forEach(param => {
                        newJsonRequest[param.name]= param.value;
                      })
                      console.log(newJsonRequest);
                      log.JsonRequest = newJsonRequest;  
                    }
                    return log
                  })
                );
                setLogsLoading(false);
                setErrorLogs("");   
              } 
            })
    
        } catch (error) {
          setErrorLogs("An error occurred, please try again later");
          setLogsLoading(false);
        }
        
    }, []);

    const delay = ms => new Promise(res => setTimeout(res, ms));

    const tableToPng = useCallback(async (i) => {
      setLogsJsonList(logsList[i].JsonResponse.replaceAll(":null", ':"null"').replaceAll(":true", ':"true"').replaceAll(":false", ':"false"'));
      await delay(500);
      if (tableResult.current === null) {
        return
      }
      toPng(tableResult.current, { cacheBust: true, })
        .then((dataUrl) => {
          const link = document.createElement('a')
          link.download = 'result.png'
          link.href = dataUrl
          link.click()
        })
        .catch((err) => {
          console.log(err)
        })
    }, [tableResult, logsList]);

    const hideUsernameDropdown = (e) => {
      if (!(e.target.closest('.username'))) {
        if (!(e.target.closest('.username_dropdown'))) {
          setUsernameOpen(false)
        }
      }
    }

  return (
    <div className="container" onClick={(e) => hideUsernameDropdown(e)}>
      <div className="navbar_blue">
          <Link to="/"><img className="logo" src={LogoWhite} /></Link>
          <Link to={"/api-request/" + endPointId} className="btn_white btn_navbar">
            <svg fill="#2139da" width="28px" height="28px">
                <g>
                    <g>
                    <path class="st0" d="M18.7,17.7h-7c-0.6,0-1-0.4-1-1s0.4-1,1-1h7c1.1,0,2-0.9,2-2v-6c0-0.6,0.4-1,1-1s1,0.4,1,1v6
                        C22.7,15.9,20.9,17.7,18.7,17.7z"/>
                    </g>
                    <g>
                    <path class="st0" d="M15.7,21.7c-0.3,0-0.5-0.1-0.7-0.3l-4-4c-0.4-0.4-0.4-1,0-1.4l4-4c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4l-3.3,3.3
                        l3.3,3.3c0.4,0.4,0.4,1,0,1.4C16.2,21.6,16,21.7,15.7,21.7z M10.7,21.7c-0.3,0-0.5-0.1-0.7-0.3l-4-4c-0.4-0.4-0.4-1,0-1.4l4-4
                        c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4l-3.3,3.3l3.3,3.3c0.4,0.4,0.4,1,0,1.4C11.2,21.6,11,21.7,10.7,21.7z"/>
                    </g>
                </g>
            </svg>
          {props.lang == "spanish" ? "Regresar" : "Return"}
        </Link>

          <div className="btn_blue_check_container">
            <div className={"btn_blue btn_checkbox" + (props.lang == "spanish" ? " selected" : "")} onClick={() => props.changeLanguage("spanish")}>{props.lang == "spanish" ? "Español" : "Spanish"}</div>
            <div className={"btn_blue btn_checkbox" + (props.lang == "english" ? " selected" : "")} onClick={() => props.changeLanguage("english")}>{props.lang == "spanish" ? "Inglés" : "English"}</div>
          </div>

            <div className="username" onClick={() => setUsernameOpen(!usernameOpen)}>
              <span>{props.user.username}</span>
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 10l5 5 5-5z"/></svg>
            </div>
            {
              usernameOpen &&
              <div className="username_dropdown">
                <h3>{props.lang == "spanish" ? "Mi Cuenta" : "My Account"}</h3>
                <svg onClick={() => setUsernameOpen(!usernameOpen)} className="username_dropdown_close" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
                <div className="username_info">
                  <p><span>{props.lang == "spanish" ? "Nombre " : "Name "}:</span> {props.user.username}</p>
                  <p><span>{props.lang == "spanish" ? "Empresa " : "Company "} :</span> -</p>
                </div>
                <h3>{props.lang == "spanish" ? "APIs Activas" : "Active APIs"}</h3>
                <div className="methods_list">
                {
                  props.methodsList.length > 0 &&
                  props.methodsList
                  .filter(method => method.ApiName.toLowerCase() != "moffin"
                      && method.ApiName.toLowerCase() != "facturapi"
                      && method.ApiName.toLowerCase() != "weesing"
                      && method.ApiName.toLowerCase() != "weesign"
                      && method.ApiName.toUpperCase() != "COPOMEX (CÓDIGO POSTAL MEXICANO) "
                      && method.ApiName.toUpperCase() != "EXCHANGE RATES"
                      && method.ApiName.toLowerCase() != "palenca"
                      && method.ApiName.toLowerCase() != "copomex"
                      && method.ApiName.toLowerCase() != "auto api mapas"
                      && method.ApiName.toUpperCase() != "PERSON ANALYTICS"
                  )
                  .map((method, index) =>
                    <div className="username_api">
                      <h4>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 10l5 5 5-5z"/></svg>
                        {method.ApiName}
                      </h4>
                      <p>{props.lang == "spanish" ? "Paquete" : "Package"} {method.PlanType} {props.lang == "spanish" ? "consultas" : "consultations"}</p>
                      <p className="flex">
                        <span>{props.lang == "spanish" ? "Disponibles" : "Available"}: {method.NumberCall}</span>
                      </p>
                    </div>
                  )
                }
                </div>
                <div className="btn_username_container">
                  <div className="btn_blue_check_container">
                    <div className={"btn_blue btn_checkbox" + (props.lang == "spanish" ? " selected" : "")} onClick={() => props.changeLanguage("spanish")}>{props.lang == "spanish" ? "Español" : "Spanish"}</div>
                    <div className={"btn_blue btn_checkbox" + (props.lang == "english" ? " selected" : "")} onClick={() => props.changeLanguage("english")}>{props.lang == "spanish" ? "Inglés" : "English"}</div>
                  </div>
                  <div className="btn_white" onClick={props.logout()}>{props.lang == "spanish" ? "Salir" : "Exit"}</div>
                </div>
              </div>
            }
      </div>
      <div className="content content_table">
        <div className="table_log_title">
          <h2>{props.lang == "spanish" ? "Historial de consultas" : "Query history"}</h2>
          <Link to={"/api-request/" + endPointId} className="btn_blue btn_outline">{props.lang == "spanish" ? "Enviar otra solicitud" : "Send another request"}</Link>
        </div>
        { (!logsLoading || errorLogs == "") &&
          <div className="table_log">
            <table>
              <thead>
                <tr>
                  <td>{props.lang == "spanish" ? "No. de consulta" : "Query no."}</td>
                  {
                                          // when its not the endpoint equal to
                    endPointId != "3157914a-a9f9-4b13-bd2e-92d139b1f1b7" &&
                    tableHead.length > 0 && 
                    tableHead.map((param, paramIndex) => {
                      return <td key={paramIndex} className="param">{param}</td>;
                    }
                    )
                  }
                  {
                    endPointId != "df507b3d-f97e-4f06-8f5d-88c08ee085ed"
                    ? <td>{props.lang == "spanish" ? "Archivo" : "Archive"}</td>
                    : <></>
                  }
                  <td>Logs</td>
                  <td>{props.lang == "spanish" ? "Fecha" : "Date"}</td>
                </tr>
              </thead>
              <tbody>
              {
                logsList.map((log, LogIndex) => 
                  <tr key={log.ApiLogId}>
                    <td>{logsList.length - LogIndex}</td>
                    {
                      // when its not the endpoint equal to
                      endPointId != "3157914a-a9f9-4b13-bd2e-92d139b1f1b7" &&
                      tableHead.map((param, paramIndex) => 
                        <td key={LogIndex + "" + paramIndex}><span>{log.JsonRequest[param]}</span></td>
                      )
                    }
                    {
                      endPointId != "df507b3d-f97e-4f06-8f5d-88c08ee085ed"
                      ? <td><div className="btn_blue" onClick={() => tableToPng(LogIndex)}>{props.lang == "spanish" ? "Descargar PNG" : "Download PNG"}</div></td>
                      : <></>
                    }
                    <td><Link to={"/api-result/" + log.ApiLogId}>Logs</Link></td>
                    <td><span>{log.CreationDate.substring(8,10) + "/" + log.CreationDate.substring(5,7) + "/" + log.CreationDate.substring(0,4)}</span></td>
                  </tr>
                )
              }
              </tbody>
            </table>
          </div>
        }
      </div>
      <div className="count_sticker">
            <div className="count_label"><span style={props.lang == "english" ? {width: 185, right: -75} : {} }>{props.lang == "spanish" ? "CONTADOR DE CONSTULTAS" : "CONSULT COUNTER"}</span></div>
            <div className="count_container">
                <h5>{props.lang == "spanish" ? "CONTADOR DE CONSTULTAS" : "CONSULT COUNTER"}</h5>
                <p>{props.lang == "spanish" ? "Paquete" : "Package"}<br /><strong>
                {count.PlanType != "" ? count.PlanType : "-" }
                </strong></p>
                <p className="count_info">{props.lang == "spanish" ? "Realizadas" : "Performed" }: <span>
                  {count.CountLog}
                </span></p>
                <p className="count_info">{props.lang == "spanish" ? "Disponibles" : "Available"}: <span>
                  {count.NumberCall != "" ? count.NumberCall : "-" }
                </span></p>
                <div className="count_bar"><div className="count_disponible_bar"></div></div>
            </div>
        </div>
      <div className="table_logs">
        <div className="table_request" ref={tableResult}>
          {logsList.length > 0 && <JsonToTable json={JSON.parse(logsJsonList)} />}
        </div>
      </div>
    </div>
  );

}

export default ApiLog;